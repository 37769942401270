import i18next from 'i18next';
import { Dispatch } from 'redux';
import * as Sentry from '@sentry/browser';
import { AuthResponse } from 'routes/LoginPage/models';

type ResetPasswordState = {
    isActivateAccount: boolean;
    resetPasswordErrors: { [key: string]: string };
};

const resetPasswordState: ResetPasswordState = {
    isActivateAccount: false,
    resetPasswordErrors: {},
};

const resetPasswordModel = {
    state: resetPasswordState,
    reducers: {
        setResetPasswordErrors(
            state: ResetPasswordState,
            payload: { errors: Record<string, string> },
        ): ResetPasswordState {
            return {
                ...state,

                resetPasswordErrors: payload.errors,
            };
        },
    },
    effects: (dispatch: Dispatch) => ({
        async resetPassword(payload: {
            token: string | undefined;
            password: string;
            passwordConfirmation: string;
            onResetPasswordSuccess: () => void;
        }) {
            return dispatch.API.post({
                params: {
                    endpoint: 'auth/reset_password',
                    body: {
                        token: payload?.token,
                        password: payload?.password,
                        passwordConfirmation: payload?.passwordConfirmation,
                    },
                },
                successAction: (response: AuthResponse) => {
                    payload.onResetPasswordSuccess();
                    dispatch.vision.setAuthenticatedState(response);
                },
                failureAction: (
                    _: string,
                    response: {
                        [errors: string]: { [key: string]: string };
                    },
                ) => {
                    if (!response.errors) {
                        Sentry.captureMessage(
                            `A previous error was not handled correctly because the response.errors object is missing: ${response}`,
                        );
                        return;
                    }
                    const formatedErrors = Object.fromEntries(
                        Object.entries(response.errors).map(([key, value]) => [
                            key,
                            i18next.t(value, { ns: 'backend' }),
                        ]),
                    );
                    dispatch.resetPassword.setResetPasswordErrors({
                        errors: formatedErrors,
                    });
                },
                showFailureNotification: false,
            });
        },
    }),
};

export default resetPasswordModel;
