// @ts-expect-error [TS migration] Was not detected by flow
import amplitude from 'amplitude-js';
import _, { debounce } from 'lodash';
import {
    AccessTypeT,
    UserInfoT,
    UserRole,
    UserScopesT,
    UserT,
} from 'business/definitions';
import * as Sentry from '@sentry/browser';
import { getClientBind } from 'store/APIClient';
import { useSafeState } from 'helpers/hooks';
import { datadogRum } from '@datadog/browser-rum';
import { isAdminBudget } from 'hooks/user';
import {
    ADMIN,
    BUDGET,
    BUDGET_USER,
    DECISION,
    DECISION_USER,
    EXPLORE_USER,
    HUMAN_RESOURCES,
    HUMAN_RESOURCES_USER,
    ModuleT,
    RAWDATA,
} from '../business/constants';

export const ACTIONS = Object.freeze({
    group: 'groupement',
    ungroup: 'dégroupement',
    open: 'ouverture',
    close: 'fermeture',
    hide: 'cacher',
    show: 'afficher',
});

const bind = getClientBind();
const logger = amplitude.getInstance();
const isE2eTest = window.location.host.includes('e2e-test-app');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const pendoTrack = (eventName: string, metadata?: Record<string, any>) => {
    if (!window.pendo) {
        console.error(
            `Trying to track ${eventName}, but Pendo is not available, so ignoring it`,
        );
        return;
    }
    window.pendo.track(eventName, metadata);
};

export function goToGuideNextStep() {
    const result = window.pendo.getActiveGuide();

    if (result) {
        const { step } = result;
        step.advance();
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function trackEvent(eventName: string, metadata?: Record<string, any>) {
    if ((PROD || STAGING || PRESENTATION) && !isE2eTest) {
        logger.logEvent(eventName, metadata);
        pendoTrack(eventName, metadata);
        datadogRum.addAction(eventName, metadata);
    }
}

export function trackEventBudget(
    eventName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata?: Record<string, any>,
) {
    trackEvent(eventName, { ...metadata, module: 'Budget' });
}

export function trackEventPRH(
    eventName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata?: Record<string, any>,
) {
    trackEvent(eventName, { ...metadata, module: 'Prospective RH' });
}

const formatScopesForTracking = (
    scopes: UserScopesT,
    accessType: AccessTypeT,
) => scopes.filter((s) => s.accessType === accessType).map((s) => s.name);

function mapUserRolesToPendo(roles: UserRole[]) {
    return {
        admin: roles.includes(ADMIN),
        adminBudget: isAdminBudget(roles),
        budget: roles.includes(BUDGET_USER),
        decision: roles.includes(DECISION_USER),
        exploreAccess: roles.includes(EXPLORE_USER),
        humanResources: roles.includes(HUMAN_RESOURCES_USER),
        rawDataAccess: roles.includes(RAWDATA),
    };
}

function mapModulesToPendo(modules: ModuleT[]) {
    return {
        hasBudget: modules.includes(BUDGET),
        hasDecision: modules.includes(DECISION),
        hasProspectiveRH: modules.includes(HUMAN_RESOURCES),
    };
}

function initializePendo(
    user: UserT & UserInfoT,
    allowedModules: ModuleT[],
    isEssential: boolean,
    isStagingEnv: boolean,
) {
    const visitor = {
        id: `${bind}-${user.email}`,
        email: user.email,
        bind,
    };
    const account = {
        id: bind,
        bind,
    };
    if (isStagingEnv) {
        Object.keys(visitor).forEach(
            (key) => (visitor[key] = `STAGING-${visitor[key]}`),
        );
        Object.keys(account).forEach(
            (key) => (account[key] = `STAGING-${account[key]}`),
        );
    }
    window.pendo.initialize({
        visitor: {
            ...visitor,
            ...mapUserRolesToPendo(user.roles),
        },
        account: {
            ...account,
            ...mapModulesToPendo(allowedModules),
            isEssential,
        },
    });
}

function setDatadogUser(user: UserT & UserInfoT) {
    datadogRum.setUser({
        id: `${bind}__${user.id.toString()}`,
        email: user.email,
        name: `${user.firstname} ${user.lastname}`,
        bind,
    });
}

/**
 * Tracks an anonymous user on DataDog, Sentry and Pendo
 */
export function trackAnonymousUser() {
    const anonymUser = {
        id: 'anonymous',
        email: 'anonymous',
        bind,
    };

    if (PROD || STAGING || PRESENTATION) {
        datadogRum.setUser({
            id: `${getClientBind()}__${anonymUser.id.toString()}`,
            email: anonymUser.email,
            bind: getClientBind(),
        });
    }
    Sentry.setUser({
        email: anonymUser.email,
        id: anonymUser.id,
    });
    if ('pendo' in window) {
        if (PROD || PRESENTATION) {
            window.pendo.initialize({
                visitor: {
                    id: `${bind}-${anonymUser.email}`,
                    email: anonymUser.email,
                    // custom info:
                    bind,
                },
                account: {
                    id: bind,
                    // custom info:
                    bind,
                },
            });
        }
        if (STAGING) {
            window.pendo.initialize({
                visitor: {
                    id: `STAGING-${bind}-${anonymUser.email}`,
                    email: `STAGING-${anonymUser.email}`,
                    // custom info:
                    bind: `STAGING-${bind}`,
                },
                account: {
                    id: `STAGING-${bind}`,
                    // custom info:
                    bind: `STAGING-${bind}`,
                },
            });
        }
    }
}

export function trackUser(
    user: UserT & UserInfoT,
    allowedModules: ModuleT[],
    isEssential: boolean,
) {
    if (PROD || STAGING || PRESENTATION) {
        setDatadogUser(user);
    }

    if (PROD) {
        logger.setUserId(user.email);
        logger.setUserProperties({
            bind,
            email: user.email,
            roles: user.roles,
            perimetersFullAccess: formatScopesForTracking(
                user.scopes,
                'full_access',
            ),
            perimetersRestricted: formatScopesForTracking(
                user.scopes,
                'restricted',
            ),
            perimetersNoAccess: formatScopesForTracking(
                user.scopes,
                'no_access',
            ),
        });
    }

    // @ts-expect-error [TS migration] Was not detected by flow
    if (PROD && window._hsq && window.HubSpotConversations) {
        // @ts-expect-error [TS migration] Was not detected by flow
        window._hsq.push([
            'identify',
            {
                email: user.email,
                ...(user.firstname && { firstname: user.firstname }),
                ...(user.lastname && { lastname: user.lastname }),
                ...(user.job && { jobtitle: user.job }),
                ...(user.gender && {
                    salutation: user.gender === 'M' ? 'Monsieur' : 'Madame',
                }),
            },
        ]);
        // @ts-expect-error [TS migration] Was not detected by flow
        window._hsq.push(['trackPageView']);

        // @ts-expect-error [TS migration] Was not detected by flow
        window.HubSpotConversations.widget.refresh();
    }

    Sentry.setUser({
        email: user.email,
        id: user.email,
    });

    // ads blocker can block pendo, we check if it is available
    if ('pendo' in window) {
        if (PROD || PRESENTATION || STAGING) {
            initializePendo(user, allowedModules, isEssential, STAGING);
        }
    }
}

export const useGetTrackAgGridGrouping = (
    eventName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata?: { [key: string]: any },
): ((arg1: string[]) => void) => {
    const [groupColumns, setGroupColumns] = useSafeState<string[]>(
        [],
        (o, n) => _.intersection(o, n).length === _.union(o, n).length,
    );

    return (newGroupCols) => {
        let action;
        let column;
        if (newGroupCols.length > groupColumns.length) {
            action = ACTIONS.group;
            column = newGroupCols.find((c) => !groupColumns.includes(c));
        } else if (newGroupCols.length < groupColumns.length) {
            action = ACTIONS.ungroup;
            column = groupColumns.find((c) => !newGroupCols.includes(c));
        }
        if (column) {
            trackEventBudget(eventName, { ...metadata, action, column });
        }
        setGroupColumns(newGroupCols);
    };
};

export const useGetAgGridTracking = (
    eventGroup: {
        gridGrouping: string;
        gridSearch: string;
        gridSort: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata?: { [key: string]: any },
): {
    onSearch: () => void;
    onSort: () => void;
    onGroup: (arg1: { columns: { colDef: { headerName: string } }[] }) => void;
} => {
    const trackGrouping = useGetTrackAgGridGrouping(
        eventGroup.gridGrouping,
        metadata,
    );
    return {
        onSearch: debounce(() => {
            trackEventBudget(eventGroup.gridSearch, metadata);
        }, 3000),
        onSort: () => {
            trackEventBudget(eventGroup.gridSort, metadata);
        },
        onGroup: (event) =>
            trackGrouping(event.columns.map((c) => c.colDef.headerName)),
    };
};

export const TRACKABLE_EVENTS = {
    budget: {
        budgetOverview: {
            totals: {
                refreshData: 'budget/vuedaf/totaux/click-actualiser-donnees',
                refreshColumns: 'budget/vuedaf/totaux/click-actualiser-colonne',
                expandBlock: 'budget/vuedaf/totaux/depli-bloc',
                exportBudgetBalance:
                    'budget/vuedaf/totaux/export-grands-équilibres',
                exportRatio: 'budget/vuedaf/totaux/export-ratios',
            },
            analyse_arguments: {
                refreshData:
                    'budget/vuedaf/analyse-arguments/click-actualiser-donnees',
                export: 'budget/vuedaf/analyse-arguments/export',
                penModalOpened:
                    'budget//*/budget_overview/analyse_arguments/*/open-inscriptions-modal',
                pivotGridExport:
                    'budget//*/budget_overview/analyse_arguments/*/export',
                saveFilter:
                    'budget//*/budget_overview/analyse_arguments/*/edit-filter',
                openArgumentModal:
                    'budget//*/budget_overview/analyse_arguments/*/open-arguments-modal',
                closeArgumentModal:
                    'budget//*/budget_overview/analyse_arguments/*/close-arguments-modal',
                openArgument:
                    'budget//*/budget_overview/analyse_arguments/*/open-argument',
                sortArgument:
                    'budget//*/budget_overview/analyse_arguments/*/edit-arguments-modal-sort',
            },
            inscriptions: {
                expenseIncomeNewTab: 'budget/vuedaf/inscriptions/ouverture-DR',
                refreshData:
                    'budget/vuedaf/inscriptions/click-actualiser-donnees',
                export: 'budget/vuedaf/inscriptions/export',
            },
            operations: {
                unpackService: 'budget/vuedaf/opérations/depli-services',
                refreshData:
                    'budget/vuedaf/opérations/click-actualiser-donnees',
                export: 'budget/vuedaf/opérations/export',
                operationClicked: 'budget/vuedaf/operations/clic-opération',
                draftOperation: {
                    create: 'budget/vuedaf/operations/creer-operation-manty',
                    confirmCreation:
                        'budget/vuedaf/operations/valider-creation-operation-manty',
                    toggleDetails:
                        'budget/vuedaf/operations/deplier-operation-manty',
                    delete: 'budget/vuedaf/operations/supprimer-operation-manty',
                    confirmDeletion:
                        'budget/vuedaf/operations/valider-supprimer-operation-manty',
                    rename: 'budget/vuedaf/operations/renommer-operation-manty',
                    confirmRename:
                        'budget/vuedaf/operations/valider-renommer-operation-manty',
                    editAssociations:
                        'budget/vuedaf/operations/acces-operation-manty',
                    confirmEditAssociations:
                        'budget/vuedaf/operations/valider-acces-operation-manty',
                    associate:
                        'budget/budgetproposal/operation/ajout-operation-manty-a-inscription',
                },
            },
            aps: {
                pageOpened: 'budget/vuedaf/aps/consultation-page',
                unpackOperations: 'budget/vuedaf/aps/depli-operations',
                refreshData: 'budget/vuedaf/aps/click-actualiser-donnees',
                export: 'nom : budget/vuedaf/aps/export',
            },
            projections: {
                atterrissage: {
                    pageOpened:
                        'budget/vuedaf/projections/atterrissage/consultation-page',
                    amountUpdated:
                        'budget/vuedaf/projections/atterrissage/montant-modif',
                    detailsClicked:
                        'budget/vuedaf/projections/atterrissage/clic-detail',
                    commentUpdated:
                        'budget/vuedaf/projections/atterrissage/justif-modif',
                    statusUpdated:
                        'budget/vuedaf/projections/atterrissage/statut-modif',
                    filtered: 'budget/vuedaf/projections/atterrissage/filtre',
                    sectionToggled:
                        'budget/vuedaf/projections/atterrissage/toggle-section',
                    resourceToggled:
                        'budget/vuedaf/projections/atterrissage/toggle-ressource',
                    groupingChanged:
                        'budget/vuedaf/projections/atterrissage/modif-ordre-groupement',
                    export: 'budget/vuedaf/projections/atterrissage/export                        ',
                },
                pluriannual: {
                    pageOpened:
                        'budget/vuedaf/projections/pluriannuelles/consultation-page',
                    filtered:
                        'budget/vuedaf/projections/pluriannuelles/modif-filtre',
                    sectionToggled:
                        'budget/vuedaf/projections/pluriannuelles/toggle-section',
                    amountUpdated:
                        'budget/vuedaf/projections/pluriannuelles/montant-modif',
                    statusUpdated:
                        'budget/vuedaf/projections/pluriannuelles/statut-modif',
                    pinnedPanelToggled:
                        'budget/vuedaf/projections/pluriannuelles/toggle-volet-saisie',
                    groupingChanged:
                        'budget/vuedaf/projections/pluriannuelles/modif-ordre-groupement',
                    export: 'budget/vuedaf/projections/pluriannuelles/export',
                },
            },
        },
        budgetProposal: {
            pageOpened: 'budget/budgetproposal/consultation-page',
            viewByResourceTabClicked:
                'budget/budgetproposal/clic-onglet-ressources',
            viewByOperationTabClicked:
                'budget/budgetproposal/clic-onglet-opérations',
            operationClicked: 'budget/budgetproposal/clic-opération',
            draftOperation: {
                create: 'budget/budget/budgetproposal/creer-operation-manty',
                confirmCreation:
                    'budget/budgetproposal/valider-creation-operation-manty',
            },
            filterBudgetLineCode:
                'budget/budgetproposal/filtre-catégorie-budgétaire',
            expenseIncome: {
                operations: {
                    gridGrouping:
                        'budget/budgetproposal/depensesrecettes/opérations/groupement-tableau',
                    gridSearch:
                        'budget/budgetproposal/depensesrecettes/opérations/recherche-tableau',
                    gridSort:
                        'budget/budgetproposal/depensesrecettes/opérations/tri-tableau',
                    toggleEmpty:
                        'budget/budgetproposal/depensesrecettes/opérations/click-toggle-opérations-non-utilisées',
                },
                imputations: {
                    gridGrouping:
                        'budget/budgetproposal/depensesrecettes/imputations/groupement-tableau',
                    gridUnpack:
                        'budget/budgetproposal/depensesrecettes/imputations/dépliage-inscriptions',
                    gridSearch:
                        'budget/budgetproposal/depensesrecettes/imputations/recherche-tableau',
                    labelSearch:
                        'budget/budgetproposal/depensesrecettes/imputations/recherche-libellé',
                    gridSort:
                        'budget/budgetproposal/depensesrecettes/imputations/tri-tableau',
                    toggleEmpty:
                        'budget/budgetproposal/depensesrecettes/imputations/click-toggle-imputations-non-utilisées',
                    exportCSV:
                        'budget/budgetproposal/depensesrecettes/click-export-CSV',
                    exportExcel:
                        'budget/budgetproposal/depensesrecettes/click-export-excel',
                    addOrphanInscription:
                        'budget/budgetproposal/depensesrecettes/ajout-orpheline',
                    addOrphanOperation:
                        'budget/budgetproposal/depensesrecettes/ajout-operation-orpheline',
                    classifyOrphan:
                        'budget/budgetproposal/depensesrecettes/classer-orpheline',
                },
                chartBySelectedDim:
                    'budget/budgetproposal/depensesrecettes/clic-dropdown-graph-propositions-budgétaires-par',
                operationsTabClicked:
                    'budget/budgetproposal/depensesrecettes/click-vue-opérations',
                imputationsTabClicked:
                    'budget/budgetproposal/depensesrecettes/click-vue-imputations',
                filterBudgetLineCode:
                    'budget/budgetproposal/depensesrecettes/filtre-catégorie-budgétaire',
            },
            analyse: {
                pageOpened: 'budget/budgetproposal/analyse/consultation-page',
                argumentSave:
                    'budget/budgetproposal/analyse/enregistrement-argumentaire',
                pivotGridExport: 'budget/budgetproposal/analyse/export-tcd',
                penModalOpened:
                    'budget/budgetproposal/analyse/modalestylo/ouverture-modale',
                saveFilter:
                    'budget//*/budget_proposal/*/resource/*/analyze/edit-filter',
            },
            allArguments: {
                create: 'budget/budgetproposal/argumentaires/creation',
                rename: 'budget/budgetproposal/argumentaires/renommage',
                delete: 'budget/budgetproposal/argumentaires/suppression',
                duplicate: 'budget/budgetpurposal/argumentaires/duplication',
            },
            argument: {
                pageOpened:
                    'budget/budgetproposal/argumentaire/consultation-page',
                layoutChange: 'budget/budgetproposal/argumentaire/mise-en-page',
                backButton: 'budget/budgetproposal/argumentaire/click-retour',
                share: 'budget/budgetproposal/argumentaire/click-partage',
                pdfExport: 'budget/budgetproposal/argumentaire/export-pdf',
                pivotGridExport:
                    'budget/budgetproposal/argumentaire/export-tcd',
                penModalOpened:
                    'budget/budgetproposal/argumentaire/modalestylo/ouverture-modale',
            },
            projection: {
                atterrissage: {
                    pageOpened:
                        'budget/budgetproposal/projections/atterrissage/consultation-page',
                    statusUpdated:
                        'budget/budgetproposal/projections/atterrissage/statut-modif',
                    amountUpdated:
                        'budget/budgetproposal/projections/atterrissage/montant-modif',
                    commentUpdated:
                        'budget/budgetproposal/projections/atterrissage/justif-modif',
                    groupingChanged:
                        'budget/budgetproposal/projections/atterrissage/modif-ordre-groupement',
                    filterBudgetLineCode:
                        'budget/budgetproposal/projections/filtre-catégorie-budgétaire',
                },
                pluriannual: {
                    pageOpened:
                        'budget/budgetproposal/projections/pluriannuelles/consultation-page',
                    statusUpdated:
                        'budget/budgetproposal/projections/pluriannuelles/statut-modif',
                    amountUpdated:
                        'budget/budgetproposal/projections/pluriannuelles/montant-modif',
                    pinnedPanelToggled:
                        'budget/budgetproposal/projections/pluriannuelles/toggle-volet-saisie',
                    groupingChanged:
                        'budget/budgetproposal/projections/pluriannuelles/modif-ordre-groupement',
                },
            },
        },
        general: {
            labelSearch: 'budget/general/inscriptions/recherche-libellé',
            gridGrouping: 'budget/general/inscriptions/groupement-tableau',
            addCategory: 'budget/general/inscriptions/ajout-catégorie',
            associateCategory:
                'budget/general/inscriptions/attribution-catégorie',
            detailsOpened: 'budget/general/inscriptions/consultation-détail',
            addComment:
                'budget/general/inscriptions/ajout-commentaire-historique',
            addInscription: 'budget/general/inscriptions/ajout-inscription',
            addOrphanInscriptionToggle:
                'budget/general/inscriptions/ajout-orpheline-toggle',
            editInscription: 'budget/general/inscriptions/édition-inscription',
            moveInscription:
                'budget/general/inscriptions/déplacement-inscription',
            deleteInscription:
                'budget/general/inscriptions/suppression-inscription',
            changeStatusBatch:
                'budget/general/inscriptions/changement-statuts-batch',
            addInscriptionCommentMention:
                'budget/general/inscriptions/historique/ajout-tag',
            operation360: {
                editDescription:
                    'budget/general/operation360/edition-paragraphe',
                backButtonClick: 'budget/general/operation360/click-retour',
                addSemiOrphanInscription:
                    'budget/general/operation360/ajout-semi-orpheline',
                addInscription: 'budget/general/operation360/ajout-inscription',
                clickMentionCharTool:
                    'budget/general/operation360/paragraphe/clic-tool-tag',
                addDescriptionMention:
                    'budget/general/operation360/paragraphe/ajout-tag',
            },
            fullScreen: {
                open: 'budget//*/budget_proposal/*/resource/*/*/imputations/click-open-fullscreen',
                close: 'budget//*/budget_proposal/*/resource/*/*/imputations/click-close-fullscreen',
            },
            keyDates: 'budget/display-key-dates',
        },
        settings: {
            addBudget: 'budget//*/admin/budget/manage_budget/click-add-budget',
            confirmAddBudget:
                'budget//*/admin/budget/manage_budget/confirm-add-budget',
            confirmResource:
                'budget//*/admin/budget/manage_budget/confirm-allow-budget-edit',
            confirmAtterrissageVentilation:
                'budget//*/admin/budget/manage_budget/confirm-atterrissage-axes',
            confirmPluriannualProjectionVentilation:
                'budget//*/admin/budget/manage_budget/confirm-pluriannual-projections-axes',
            confirmDuplicateSettings:
                'budget//*/admin/budget/manage_budget/confirm-duplicate-settings',
            initializeInscriptions:
                'budget//*/budget_admin/manage_budget/*/click-initialize',
            confirmInitializeInscriptions:
                'budget//*/budget_admin/manage_budget/*/confirm-initialize',
            duplicateSettings:
                'budget//*/admin/budget/manage_budget/click-duplicate-settings',
            duplicateArgumentaire: {
                click: 'settings/budget/duplicate-argu/click',
                confirmDuplication:
                    'settings/budget/duplicate-argu/confirm-duplication',
                budget: 'settings/budget/duplicate-argu/toggle-budget',
                section: 'settings/budget/duplicate-argu/toggle-section',
                resource: 'settings/budget/duplicate-argu/toggle-ressource',
            },
            projects: {
                search: 'budget/réglages/catégories/recherche',
                sort: 'budget/réglages/catégories/tri-tableau-catégorie',
                add: 'budget/réglages/catégories/clic-ajouter',
                confirmAdd: 'budget/réglages/catégories/confirmation-ajout',
                delete: 'budget/réglages/catégories/clic-supprimer',
                confirmDelete:
                    'budget/réglages/catégories/confirmation-suppression',
                patch: 'budget/réglages/catégories/clic-renommer',
                confirmPatch:
                    'budget/réglages/catégories/confirmation-renommage',
            },
            toggleAddReported: 'budget/toggle-add-reported',
            cadrage: {
                exercice: '//*/budget_admin/manage_cadrage/select-year',
                metric: '//*/budget_admin/manage_cadrage/select-cadrage-base',
                custom: {
                    add: 'budget//*/budget_admin/manage_cadrage/confirm-add-custom-cadrage',
                    edit: 'budget//*/budget_admin/manage_cadrage/confirm-edit-custom-cadrage',
                    delete: 'budget//*/budget_admin/manage_cadrage/confirm-delete-custom-cadrage',
                },
            },
        },
        ux: {
            breadcrumbsClick: 'budget/ux/click-fil-darianne',
            homeBudgetClick: 'budget/ux/click-icone-budget',
            vueDafClick: 'budget/ux/click-bouton-vue-daf',
            homeFiltersChanged: 'budget/ux/changement-filtres-accueil',
            settingsBudgetClick: 'budget/ux/clic-réglages-budget',
            keyDatesWidgetClick: 'budget/ux/clic-widget-dates-clés',
        },
    },
    human_resources: {
        simulation: {
            create: 'prh/simulation_home/simulation_create',
            exportAccountablePdf: 'prh/simulations/result_accounting_export',
            accountableDisplay:
                'prh/simulations/result_accounting_view_display',
            duplicate: 'prh/simulation_home/simulation_duplicate',
            scenario: {
                updateHypotheses: 'prh/simulation/scenario/new_values_apply',
                smicDetail: 'prh/simulation/scenario/view_details_SMIC',
                createIfseHypotheses:
                    'prh/simulation/scenario/new_hypothesis_validate',
            },
            recurrent: {
                createAgentDepartureHypothesis:
                    'prh/simulation/recurrent_departure_hypothesis_validate',
                dataRefresh: 'prh/simulation/recurrent_data_refresh',
                createAgentArrivalHypothesis:
                    'prh/simulation/recurrent_new_agent_hypothesis_validate',
                validateAgentPonctualPrimeHypothesis:
                    'prh/simulation/recurrent_one_time_bonus_hypothesis_validate',
                createIfseHypothesis:
                    'prh/simulation/recurrent_monthly_premium_hypothesis_validate',
                createGradeAdvancementHypothesis:
                    'prh/simulation/recurrent_promotion_hypothesis_validate',
            },
            nonRecurrent: {
                applyHypothesesConfig:
                    'prh/simulation/non_recurrent_statut_hypothesis_setup_apply',
                createAgentDepartureHypothesis:
                    'prh/simulation/non_recurrent_departure_hypothesis_agent_validate',
                validateEnvelopeHypothesis:
                    'prh/simulation/non_recurrent_hypothesis_envelope_validate',
                agentDataRefresh:
                    'prh/simulation/non_recurrent_hypothesis_agent_data_refresh',
                envelopeDataRefresh:
                    'prh/simulation/non_recurrent_hypothesis_envelope_data_refresh',
                validateAgentPonctualPrimeHypothesis:
                    'prh/simulation/non_recurrent_one_time_bonus_hypothesis_agent_validate',
            },
            payComponents: {
                validateUpdateComponentEnvelopeHypothesis:
                    'prh/simulation/components_hypothesis_validate',
                validateNewComponentEnvelopeHypothesis:
                    'prh/simulation/components_hypothesis_new_envelope_validate',
                dataRefresh:
                    'prh/simulation/components_hypothesis_data_refresh',
            },
            hypothesesSummary: {
                openPage: 'prh/simulation/simulation_resume_display',
            },
            results: {
                openPage: 'prh/simulation/simulation_result_display',
            },
        },
        retrospective: {
            create: 'prh/retrospective_home/retrospective_create',
        },
        payState: {
            create: 'prh/human_ressources/pay_state_home_create',
        },
    },
    decision: {} as object,
    global: {
        ux: {
            notificationsCenter: {
                clickIcon: 'global/ux/clic-centre-notifications',
                markAllAsRead:
                    'global/ux/centre-notifications/clic-tout-marquer-comme-lu',
                clickNotification:
                    'global/ux/centre-notifications/clic-notification',
            },
        },
    },
};
