import vizInfos from 'helpers/vizInfos';
import { PaletteName } from 'styles/theme';
import { ColDef } from 'ag-grid-community';
import {
    ACCESS_BUDGETS_GESTION,
    ACCESS_CADRAGE_GESTION,
    ACCESS_DAF,
    ADMIN,
    ADMIN_BUDGET,
    ADMIN_CATEGORIES,
    BUDGET_USER,
    DECISION_USER,
    EXPLORE_USER,
    HUMAN_RESOURCES_USER,
    RAWDATA,
    SUPER_FINANCES_WRITER,
    SUPPORT,
    WIDGET_TYPE,
    SCOPE_PERMISSION_READ,
    SCOPE_PERMISSION_WRITE,
} from './constants';

export const DataTypes = Object.freeze({
    date: 'date',
    datetime: 'datetime',
    integer: 'integer',
    float: 'float',
    fraction: 'fraction',
    money: 'money',
    category: 'category',
    text: 'text',
    time: 'time',
    coordinates: 'coordinates',
    polygon: 'polygon',
    tree: 'tree',
    table: 'table',
    year: 'year',
});
/* An enumeration of the data types which could be cumulated*/
export const canCumulateDataTypes: DataTypesT[] = [
    DataTypes.date,
    DataTypes.datetime,
    DataTypes.float,
    DataTypes.integer,
    DataTypes.year,
];

/* An enumeration of the different FilterOperations. */
export const FilterOperations = Object.freeze({
    greater: 'greater',
    greater_or_equal: 'greater_or_equal',
    lower: 'lower',
    lower_or_equal: 'lower_or_equal',
    equal: 'equal',
    not_equal: 'not_equal',
    in_: 'in_',
    not_in: 'not_in',
    contains: 'contains',
    not_contains: 'not_contains',
    is_null: 'is_null',
    is_not_null: 'is_not_null',
    between: 'between',
    extract: 'extract',
});

/* An enumeration of the different FilterOperations. */
export const DynamicFilterOperations = Object.freeze({
    last: 'last',
    current: 'current',
    next_: 'next_',
    greatest: 'greatest',
    smallest: 'smallest',
});

export const dateSpecialValues = [
    'current_year',
    'last_year',
    'current_month',
    'last_month',
    'current_week',
    'last_week',
];

/* An enumeration of the different scales. */
export const TimeScales = Object.freeze({
    day: 'day',
    week: 'week',
    month: 'month',
    quarter: 'quarter',
    year: 'year',
    schoolYear: 'school_year',
    dow: 'dow',
});

export const SlidingTimeScales = Object.freeze({
    slidingYear: 'sliding_year',
    slidingWeek: 'sliding_week',
});

export const DimensionAggregationStrategies = Object.freeze({
    tree: 'tree',
    flat: 'flat',
});

const FO = FilterOperations;
export const operations: Record<
    DataTypesT,
    (FilterOperationsT | DynamicFilterOperationsT)[]
> = {
    polygon: [],
    table: [],
    coordinates: [],
    date: [
        FO.greater,
        FO.greater_or_equal,
        FO.equal,
        FO.lower,
        FO.lower_or_equal,
        FO.is_null,
        FO.is_not_null,
        FO.between,
    ],
    datetime: [
        FO.greater,
        FO.greater_or_equal,
        FO.equal,
        FO.lower,
        FO.lower_or_equal,
        FO.is_null,
        FO.is_not_null,
        FO.between,
    ],
    integer: [
        FO.greater,
        FO.greater_or_equal,
        FO.lower,
        FO.lower_or_equal,
        FO.equal,
        FO.not_equal,
        FO.is_null,
    ],
    float: [
        FO.greater,
        FO.greater_or_equal,
        FO.lower,
        FO.lower_or_equal,
        FO.equal,
        FO.not_equal,
        FO.is_null,
    ],
    fraction: [
        FO.greater,
        FO.greater_or_equal,
        FO.lower,
        FO.lower_or_equal,
        FO.equal,
        FO.not_equal,
        FO.is_null,
    ],
    money: [
        FO.greater,
        FO.greater_or_equal,
        FO.lower,
        FO.lower_or_equal,
        FO.equal,
        FO.not_equal,
        FO.is_null,
    ],
    category: [
        FO.in_,
        FO.not_in,
        FO.is_null,
        FO.is_not_null,
        FO.contains,
        FO.not_contains,
    ],
    text: [FO.contains, FO.not_contains, FO.is_null, FO.is_not_null],
    time: [FO.greater, FO.lower, FO.equal, FO.not_equal, FO.is_null],
    tree: [
        FO.in_,
        FO.not_in,
        FO.is_null,
        FO.is_not_null,
        FO.contains,
        FO.not_contains,
    ],
    year: [
        FO.greater_or_equal,
        FO.equal,
        FO.not_equal,
        FO.lower_or_equal,
        FO.is_null,
    ],
};

export type AccessTypeT = 'full_access' | 'restricted' | 'no_access';

export type ScopeBlockT = {
    name: string;
    values: string[];
};
export type UserScopeT = {
    name: string;
    accessType: AccessTypeT;
    blocks: ScopeBlockT[];
};

export type UserScopesT = UserScopeT[];

export type UserRole =
    | typeof ADMIN
    | typeof RAWDATA
    | typeof ACCESS_DAF
    | typeof ADMIN_CATEGORIES
    | typeof SUPER_FINANCES_WRITER
    | typeof ACCESS_CADRAGE_GESTION
    | typeof ACCESS_BUDGETS_GESTION
    | typeof SUPPORT
    | typeof DECISION_USER
    | typeof EXPLORE_USER
    | typeof BUDGET_USER
    | typeof HUMAN_RESOURCES_USER;

export type UserRoleHeader = typeof ADMIN_BUDGET;

export type RightType = 'all' | 'read' | 'write';

export type UserRightT = {
    resourceSoftwareId: string;
    resourceType: string;
    rights: RightType[];
};

export type AccessibleValues = {
    resource: string;
    values: string[];
};

export type PermissionType =
    | typeof SCOPE_PERMISSION_READ
    | typeof SCOPE_PERMISSION_WRITE;

export type GenderType = 'F' | 'M';

export type UserInfoT = {
    email: string;
    firstname: string;
    lastname: string;
    gender?: GenderType;
    job: string;
};

export type UserT = {
    id: number;
    authMethods: string[];
    email: string;
    gestionnaireNodes: string[];
    homeDashboardSlug: string;
    isActive: boolean;
    isAdmin: boolean;
    isScopeGestionnaireUnrestricted: boolean;
    isScopeOrgChartUnrestricted: boolean;
    orgChartNodes: string[];
    rights: string[];
    roles: UserRole[];
    scopes: UserScopesT;
    writeScopes: UserScopesT;
    userRights?: UserRightT[];
    tutorialSteps: { [key: string]: boolean };
} & UserInfoT;

export type EnrichedUserT = UserT & { userRightsAccessType?: AccessTypeT };

export type BlockT = { block: string; operation: string };

export type ExpressionT = {
    block?: string;
    operation?: string;
    any?: BlockT[];
    all?: BlockT[];
};

export type ScopeT = {
    name: string;
    label: string;
    expression: ExpressionT;
    accessType?: AccessTypeT;
};

export type ProtectionT = {
    scope: string;
    blocks: string[];
};

export type ScopeOptionsT = {
    value: string;
    label: string;
    section: string;
    values: string[];
};

export type ColumnWidthT = {
    columnName: string;
    width: number;
};
export type ColumnSortingT = {
    columnName: string;
    direction: 'asc' | 'desc';
};

export type VizType = keyof typeof vizInfos;

export type AreaT = 'data' | 'column' | 'row' | null;

export type BasePivotFieldT = {
    dataField: string;
    area: AreaT;
    areaIndex?: number;
    summaryType?: string;
};

type PivotFieldT = {
    caption: string;
    dataField?: string;
    dataType: string;
    width: number;
    area?: AreaT;
    isMeasure: boolean;
    summaryType?: string;
    customizeText?: string;
    areaIndex?: number;
    expanded?: boolean;
    format?: FlowAnyObject;
    calculateSummaryValue?: (e: FlowAnyObject) => number;
    calculateCustomSummary?: (options: FlowAnyObject) => void;
};

export type OrderedColumnsT = {
    x?: ResponseColumnT;
    y: ResponseColumnT;
    z?: ResponseColumnT;
};

// Type used into PivotFieldsAggrid. It's useful to persist TCD expanded rows
export type ExpandedRowT = {
    value: string | null;
    children: ExpandedRowT[];
};

export type PivotFieldsAggridT = {
    colDefs: ColDef[];
    expandedRows: ExpandedRowT[];
};

type DimensionKey = string;
type ColorHexa = string;
export type ColorMapping = { value: string; color: ColorHexa };

export type CustomPalette = Partial<
    Record<PaletteName, Record<DimensionKey, ColorMapping[]>>
>;

export type SortDimensionT = {
    dimension: string;
    order: 'asc' | 'desc';
};

export type CustomizableChartSettings = {
    pageSize?: number;
    showGroupAggregates?: boolean;
    showAggregates?: boolean;
    showFilters?: boolean;
    minValue?: number;
    maxValue?: number;
    maxValueField?: string;
    goalValue?: number;
    numberText?: string;
    paragraphPrefix?: string;
    paragraphSuffix?: string;
    rowHeight?: 'sm' | 'md' | 'lg';
    pivotField?: string;
    decimalPrecision?: 0 | 1 | 2;
    displayBy?: 'default' | 'million' | 'thousand';
    showLegend?: boolean;
    showLegendOnRightSide?: boolean;
    areTicksRotated?: boolean;
    isStackedBars?: boolean;
    isYearlyComparison?: boolean;
    isNormalized?: boolean;
    areLabelsShown?: boolean;
    areLabelsShownInside?: boolean;
    areLabelsShownOutside?: boolean;
    areLabelsVertical?: boolean;
    isEvolutionDisplayed?: boolean;
    xAxisTitle?: string;
    yAxisTitle?: string;
    palette?: PaletteName;
    customPalette?: CustomPalette;
    eChartOptions?: string;
    reverseGroupBy?: boolean;
    pivotTableExportedFromDecisionToBudget?: boolean;
    secondaryYAxis?: string;
    wrapVerticalAxisLabels?: boolean;
    verticalAxisWidth?: 'sm' | 'md' | 'lg';
    axisLabelFontSize?: 'sm' | 'md' | 'lg' | 'default';
    sortDimension?: SortDimensionT;
    useGradientColor?: boolean;
    reverseGradientColor?: boolean;
    isDataZoomEnabled?: boolean;
};

export type ChartSettingsT = {
    columnsWidth?: ColumnWidthT[];
    columnsSorting?: ColumnSortingT[];
    pivotFields?: PivotFieldT[] | BasePivotFieldT[];
    pivotFieldsAggrid?: PivotFieldsAggridT;
    columnsHidden?: string[];
    hideToolbar?: boolean;
    columnOrder?: string[];
    useSmartMultiMetrics?: boolean;
    isMedia?: boolean;
    metricsCumulated?: string[];
    numberTitle?: string;
    mapLatCenter?: number;
    mapLonCenter?: number;
    mapZoom?: number;
    mediaUrl?: string;
    legendPosition?: FlowAnyObject;
    chartConception?: {
        id: string;
        chartDimensionKey: 'x' | 'y' | 'z';
    }[];
    dataZoomInterval?: [number, number];
} & CustomizableChartSettings;

export type ChartT = {
    queryMetrics: string[];
    queryDimensions: SentenceDimensionT[];
    queryFilters: SentenceFilterT[];
    settings: ChartSettingsT;
    vizType: VizType;
    publicToken: string;
    chartKey: number;
    key: number;
    title: string;
    tags: string[];
    disableDashboardFilters: boolean;
};

export type WidgetTypeT = keyof typeof WIDGET_TYPE;

export type PageSeparator = {
    yPos: number; // in pixels
    isCuttingWidget?: boolean;
};

export type DashboardChartT = {
    aggregation: string;
    metrics: SentenceMetricT[];
    dimensions: SentenceDimensionT[];
    filters: SentenceFilterT[];
    settings: ChartSettingsT;
    vizType: VizType;
    key: number;
    title: string;
    position: {
        [key: string]: { x: number; y: number; w: number; h: number };
    };
    disableDashboardFilters: boolean;
    alertTriggered: boolean;
    columns?: string[];
};

export type UserSharingT = {
    key: string | number;
    email: string;
    editAccess: boolean;
};

export type GroupSharingT = {
    isPublic: boolean;
    isRestricted: boolean;
    isRestrictedEditAccess: boolean;
};

export type SharingT = {
    usersSharing: UserSharingT[];
    groupSharing: GroupSharingT;
};

export type SharingPayloadT = SharingT & {
    shouldSendEmail: boolean;
    shouldSendNotification: boolean;
};

export type DashboardOwnerT = {
    id: number;
    email: string;
};

type PartialDashboardFolderT = {
    id?: number;
    name: string;
};

export type IndicatorBaseT = {
    dimensions: ExploreDimensionT[];
    filters: ExploreFilterT[];
    metrics: ExploreMetricT[];
    settings: ChartSettingsT;
    title: string;
    vizType: VizType;
};

export type IndicatorT = IndicatorBaseT & {
    dashboardKey: number;
    dashboardOwnerId: number;
    dashboardSlug: string;
    dashboardTitle: string;
    key: number;
    widgetKey: number;
};

export type LibraryChartTemplateT = IndicatorBaseT & {
    description: string | null;
    key: number;
    preview: string;
};

export type LibraryIndicatorT = LibraryChartTemplateT | IndicatorT;

export type DashboardT = {
    key: number;
    layoutId?: number;
    slug: string;
    canEdit: boolean;
    ownerId?: number;
    title: string;
    tags: string[];
    publicToken?: string;
    isArchived: boolean;
    isUserFavourite: boolean;
    isTemplate: boolean;
    isTutorial: boolean;
    owner: DashboardOwnerT;
    folder?: PartialDashboardFolderT;
    lastViewByUser?: string;
    isInPresentationMode: boolean;
} & SharingT;

type LayoutItemT = {
    x: number;
    y: number;
    h: number;
    w: number;
};

type Breakstyle = 'xs' | 'md';
type BreakstyleLayout = Record<Breakstyle, LayoutItemT>;

type WidgetContent = {
    text?: string;
    tiptap?: string;
    url?: string;
};

export type WidgetT = {
    position: BreakstyleLayout;
    type: WidgetTypeT;
    key: number;
    content?: WidgetContent;
    chart?: DashboardChartT;
    dashboardKey?: number;
    layoutId: number;
};

export type LayoutT = {
    id: number;
    widgets: WidgetT[];
};

export type ArchivedDashboardT = {
    key: number;
    title: string;
};

export type ExportFileT = 'csv' | 'xlsx' | 'image';

export const DASHBOARD_SCOPE = {
    PRIVATE: 'private',
    PUBLIC: 'public',
    RESTRICTED: 'restricted',
    SHARED: 'shared',
    TEMPLATE: 'template',
    TUTORIAL: 'tutorial',
} as const;
export type DashboardScopeT =
    (typeof DASHBOARD_SCOPE)[keyof typeof DASHBOARD_SCOPE];

// SelectionBaseT corresponds to a basic selection type
export type SelectionBaseT = {
    value: string;
    label: string;
    key?: string;
    isBadValue: boolean;
};

export type GenericSelectionBase<T> = {
    value: T;
    label: string;
    isBadValue: boolean;
};

// NodeIdT corresponds to a node element that can be either a string or a number
// that identifies the node in the tree
export type NodeIdT = string | number;

// SelectionTreeT is selected element type in a tree.
// isSubtreeSelected indicates whether the selected element is selected with its children
export type SelectionTreeT = SelectionBaseT & {
    isSubtreeSelected: boolean;
};
export const TEAM_MANTY_EMAILS = ['team@mantic.fr', 'team@manty.eu'];

export type DashboardFolderT = {
    id: number;
    name: string;
    dashboards: DashboardT[];
};

type DataGetDataPayloadT = {
    columns: RawResponseColumnT[];
    data: ResponseDataT[];
};

export type GetDataPayloadT = {
    data: DataGetDataPayloadT;
};
